<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      height="auto"
      @click:outside="cancel()"
      @keydown.esc="cancel()"
      @keydown.enter="login()"
    >
      <v-form v-model="valid" ref="form">
        <v-card rounded class="pa-8">
          <v-card-text>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-img
                  width="160px"
                  src="../../assets/logo/Logo1.jpg"
                  alt="logoDragon"
                  class="mx-auto"
                  style="max-width: 100%"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-row class="mt-n10" v-if="step == 1">
            <v-col cols="12">
              <span> ชื่อ </span>
              <v-text-field
                v-model="form.firstName"
                hide-details="auto"
                outlined
                :rules="[requiredRule]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span> นามสกุล </span>
              <v-text-field
                v-model="form.lastName"
                hide-details="auto"
                outlined
                :rules="[requiredRule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-center" v-if="step == 1">
            <v-col cols="12">
              <v-btn color="#008080" @click="submit()">
                <span style="color: white">แก้ไข</span> </v-btn
              >&emsp;
              <v-btn color="#718096" @click="cancel()">
                <span style="color: white">ยกเลิก</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mt-n4" no-gutters justify="center" v-if="step == 2">
            <v-col cols="12" md="12" sm="12" class="text-center">
              <h2 style="font-weight: 400">กรอกเบอร์มือถือ</h2>
            </v-col>

            <span>ระบบจะส่งรหัส OTP ให้ทาง SMS เพื่อยืนยัน</span>
            <v-col cols="12" md="12" sm="12" class="mt-4">
              <v-text-field
                v-model="form.telNumber"
                placeholder="กรอกเบอร์มือถือ 10 หลัก"
                solo
                background-color="white"
                dense
                @keyup.enter="OTP()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="text-center">
              <v-btn style="background: #008080" @click="OTP()"
                ><span style="color: white">ขอรหัส</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mt-n4" no-gutters justify="center" v-if="step == 3">
            <v-col cols="12" md="12" sm="12" class="text-center">
              <h2 style="font-weight: 400">ยืนยันรหัส OTP</h2>
            </v-col>

            <span>กรอกรหัส OTP ที่ได้รับจากโทรศัพท์มือถือ</span>
            <v-col cols="8" md="8" sm="8">
              <v-text-field
                v-model="otp_code"
                solo
                background-color="white"
                dense
                @keyup.enter="verifyOTP()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="mb-2 mt-n4 text-center">
              <v-btn style="background: #008080" @click="verifyOTP()"
                ><span style="color: white">ยืนยันหมายเลข OTP</span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="mb-2 text-center">
              <v-btn
                text
                style="border: solid 1px; border-color: #4ca3ad"
                @click="OTP()"
                >ขอรหัส OTP ใหม่อีกครั้ง</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { Encode, Decode } from "@/services";
export default {
  props: {
    dialog: Boolean,
  },

  data() {
    return {
      valid: false,
      step: 1,
      form: {},

      api_key: "d551e09b93553c9970e9634a5046cd5a",
      secret_key: "i0rdbEDaUdqyBo3r",
      project_key: "1b835546c3",
      otp_code: "",
      token: "",

      userData: {},
    };
  },

  created() {
    const encodedUserLogin = localStorage.getItem("No1User");
    if (encodedUserLogin !== null) {
      const decodedUserLogin = Decode.decode(encodedUserLogin);
      this.userData = JSON.parse(decodedUserLogin);
    }

    this.getData();
  },

  methods: {
    async getData() {
      this.form.accountName = this.userData.user
        ? this.userData.user.accountName
        : "";
      this.form.bankName = this.userData.user
        ? this.userData.user.bankName
        : "";
      this.form.bankNo = this.userData.user ? this.userData.user.bankNo : "";
    },
    requiredRule(v) {
      return !!v || this.$t("message.pleasfill");
    },
    async OTP() {
      const auth = {
        headers: {
          "Content-Type": "application/json",
          api_key: this.api_key,
          secret_key: this.secret_key,
        },
      };
      const params = {
        project_key: this.project_key,
        phone: this.form.telNumber,
      };
      const response = await this.axios.post(
        `https://portal-otp.smsmkt.com/api/otp-send`,
        params,
        auth
      );

      this.token = response.data.result.token;

      if (response.data.code === "000") {
        this.step = 3;
      } else if (this.telNumber == "") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณากรอกหมายเลขโทรศัพท์",
        });
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "ระบบ OTPขัดข้อง",
        });
      }
    },
    async verifyOTP() {
      const auth = {
        headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          api_key: this.api_key,
          secret_key: this.secret_key,
          // "Access-Control-Allow-Origin": "*"
        },
      };
      const params = {
        token: this.token,
        otp_code: this.otp_code,
      };

      const response = await this.axios.post(
        `https://portal-otp.smsmkt.com/api/otp-validate`,
        params,
        auth
      );

      if (response.data.result.status === true) {
        // await this.$swal.fire({
        //   showConfirmButton: false,
        //   timer: 1500,
        //   timerProgressBar: true,
        //   icon: "success",
        //   text: "ตรวจสอบสำเร็จ",
        // });
        this.sendUpdateApi();
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "รหัส OTP ของท่านผิด กรุณากรอกใหม่อีกครั้ง",
        });
      }
    },
    async submit() {
      this.$refs.form.validate();
      if (this.valid) {
        // this.step = 2;
        this.sendUpdateApi();
      }
    },

    async sendUpdateApi() {
      const auth = {
        headers: { Authorization: `Bearer ${this.userData.token}` },
      };
      const data = {
        ...this.form,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/users/` + this.userData.user.id,
        data,
        auth
      );

      if (response.data.response_status === "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: this.$t("message.addsuccess"),
          showConfirmButton: false,
          timer: 1500,
        });
        await this.relogin().then(() => {
          this.cancel();
        });
      } else {
        this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: response.data.error_msg,
        });
      }
    },

    async relogin() {
      const auth = {
        headers: { Authorization: `Bearer ${this.userData.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/${this.userData.user.id}`,
        auth
      );
      let itemUpdate = { token: this.userData.token, user: response.data.data };
      localStorage.setItem("No1User", Encode.encode(itemUpdate));
    },

    cancel() {
      this.$emit("closeDialogEditName");
    },
  },
};
</script>

<style scoped>
.custom-btn {
  min-width: 250px !important;
}
</style>